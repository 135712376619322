import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ProgramBookSlinder from "../components/program-book-slider";

const ProgramBookPage = () => (
    <Layout>
        <SEO title="KOFFIA 2024 Program book" />

        <ProgramBookSlinder />
    </Layout>
);

export default ProgramBookPage;
