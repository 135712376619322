import React from "react";
import styled, { css } from "styled-components";
import { breakpoints } from "../../styles/variables";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export const Wrapper = styled.div`
    margin: 7rem auto;
    width: 210px;
    @media (min-width: ${breakpoints.md}) {
        width: 420px;
    }
`;

export const SlideWrapper = styled.div`
    position: relative;
    opacity: 1;
    transition: transform 0.5s ease;
    cursor: pointer;
`;

const ArrowStyle = css`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 30px;
    font-weight: 900;
    color: #242424;
    cursor: pointer;
    transition: transform 0.4s;
    z-index: 1;
    &:hover {
        transform: translate(0, -50%) scale(1.2);
    }
    @media (min-width: ${breakpoints.sm}) {
        font-size: 50px;
    }
`;

export const ArrowLeft = styled(BsChevronLeft)`
    ${ArrowStyle}
    left: -50px;
`;

export const ArrowRight = styled(BsChevronRight)`
    ${ArrowStyle}
    right: -50px;
`;

export const SliderWrapper = styled.div`
    position: relative;
    margin: 2rem 0;
    .gatsby-image-wrapper {
        width: 100% !important;
    }

    img {
        transition: transform 0.6s ease !important;
    }

    .slick-slide,
    .gatsby-image-wrapper {
        height: 297px !important;
        @media (min-width: ${breakpoints.md}) {
            height: 594px !important;
        }
    }
`;

export const Left = ({ onClick }: { onClick?: () => void }) => (
    <ArrowLeft onClick={onClick} />
);
export const Right = ({ onClick }: { onClick?: () => void }) => (
    <ArrowRight onClick={onClick} />
);
