import React from "react";
import Img from "gatsby-image";
import { SlideWrapper } from "./styles";

const Slide = ({ childImageSharp }): JSX.Element => (
    <SlideWrapper>
        <Img fixed={childImageSharp.fixed} />
    </SlideWrapper>
);

export default Slide;
