import { useStaticQuery, graphql } from "gatsby";

const useData = () => {
    const data = useStaticQuery(graphql`
        query {
            allFile(filter: { relativeDirectory: { eq: "program-book" } }) {
                nodes {
                    name
                    childImageSharp {
                        fixed(width: 420, height: 594, quality: 100) {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
            }
        }
    `);
    return data.allFile.nodes.sort((a, b) => {
        const titleA = a.name;
        const titleB = b.name;
        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0;
    });
};

export default useData;
